* {
  margin: 0;
  padding: 0;
}
.footer {
  /* background-color: #d5dae0; */
  background-color: aliceblue;
}
.div_Footer {
  color: "blue";
}
.Logo {
  width: 100%;
  height: 100%;
}
.section-padding {
  padding: 2rem 6rem;
}

.footer_Email {
  font-size: 15px;
  color: blue;
}
.sb_footer {
  display: flex;
  flex-direction: column;
}
.sb_footer-Link {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.sb_footer-links-div {
  width: 300px;
  color: rgb(7, 7, 7);
}
a {
  color: rgb(25, 25, 26);
  /* color: aliceblue; */
  text-decoration: none;
}
.social-media {
  display: flex;
  flex-direction: row;
}
.social-media img {
  width: 80%;
}
.sb_footer-links-div h4 {
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 0.9rem;
}
.sb_footer-links-div p {
  font-size: 15px;
  line-height: 17px;
  margin: 0.5rem;
  cursor: pointer;
}

.sb_footer-below {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.sb_footer-below-links {
  display: flex;
  flex-direction: row;
}

.sb_footer-below-links p {
  font-size: 13px;
  line-height: 15px;
  margin-left: 2rem;
  color: rgb(19, 18, 18);
  /* color: aliceblue; */
  font-weight: 600;
}

hr {
  color: rgb(16, 15, 15);
  width: 100%;
}
.sb-footer-copyright p {
  font-size: 23px;
  line-height: 15px;
  color: rgb(31, 30, 30);

  font-size: 600;
}
/* todo ----------------------------------------------------------------------------
   todo----------------------------------------------------------------------------
  todo ---------------------------------------------------------------------------- */

/* ipad pro */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
}
/* Surface Pro 7 */
@media screen and (min-width: 912px) and (max-width: 1368px) {
}
/* ipad Air */
/* Done  */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
}
/* surface dou */
@media screen and (min-width: 540px) and (max-width: 720px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* IPone 14 Pro */
@media screen and (min-width: 430px) and (max-width: 932px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* Pixel 7  */
@media screen and (min-width: 412px) and (max-width: 915px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* Galaxy S20  */
@media screen and (min-width: 412px) and (max-width: 915px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* IPone XR */
@media screen and (min-width: 414px) and (max-width: 896px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* IPone 12 */
@media screen and (min-width: 390px) and (max-width: 844px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* IPone SE */
@media screen and (min-width: 375px) and (max-width: 667px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/* samsung Galaxy S8+  */
@media screen and (min-width: 360px) and (max-width: 740px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}

/*  Galaxy Fold  */
@media screen and (min-width: 370px) and (max-width: 653px) {
  .sb_footer-header h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .sb_footer-header h1 {
    font-size: 14px;
    line-height: 22px;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-btn p {
    font-size: 7px;
    line-height: 10px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 17px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 800px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 15px;
    color: blue;
  }
}
/*  Galaxy S20   */
@media screen and (min-width: 280px) and (max-width: 359px) {
  .sb_footer-header h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .sb_footer-header h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .section-padding {
    padding: 1.5rem 3.5rem;
  }
  .sb_footer-Link div {
    max-width: 1rem 0;
  }
  .sb_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
  .sb_footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb_footer-below-links {
    flex-direction: column;
  }
  .sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .sb_footer-header h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb_footer-links-div {
    width: 300px;
    color: rgb(7, 7, 7);
  }
  .footer_Email {
    font-size: 10px;
    color: blue;
  }
}

@media screen and (min-width: 280px) and (max-width: 769px) {
  .Title {
    font-size: 20px;
  }
}
