/* language.css */

.language-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.language-form-control {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
}

.language-select {
  display: flex;
  align-items: center;
}

.language-select img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.language-select .label-text {
  font-size: 0.9rem;
  font-weight: 400;
  color: #333;
}

.language-select span {
  margin-left: 2px;
}

.language-input-label {
  display: flex;
  align-items: center;
}

.language-input-label .label-text {
  margin-left: 10px;
  font-size: 1rem;
  font-weight: bold;
}
