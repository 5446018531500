.Text-Top {
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: 90px;
  margin-right: 90px;
}
.Email0 {
  font-size: 20px;
}
.Eamil_form {
  font-size: 20px;
}

.Logo-form {
  width: 390px;
  height: 40px;
}
.link_Button {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: aliceblue;
  transition: 0.3s ease-in-out;
}
.Text-Home-2 {
  /* text-align: center;
    justify-content: center; */
  padding-top: 30px;
  direction: rtl;
  /* padding: 50px; */
}
.Text_p {
  font-size: 22px;

  font-weight: bold;
  padding-top: 12px;
  line-height: 1.5em;
  text-align: justify;
}
.Text_pp {
  font-size: 19px;
  font-weight: bold;
  padding-top: 12px;
}
.container {
  max-width: 960px;
  margin: 100px auto;
  padding: 10px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 70px;
}
.item {
  background: green;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
#paragraph_form {
  margin: 18px 0 50px 0;
}

/* FlexBox.css */
.flex-container {
  display: flex;
  margin: 20px 0 20px 0;
  padding: 50px 0 50px 0;
  background: white;
  justify-content: space-between; /* Items will be evenly distributed in the container */
}

.flex-item-1 {
  /* border: 1px solid #ddd; */
  padding: 10px;
  font-size: 22px;
  text-align: center;
  flex: 2; /* Equal flex distribution */
}
.flex-item-2 {
  /* border: 1px solid #ddd; */
  padding: 10px !important;
  text-align: center !important;
  /* justify-content: end; */
  flex: 1 !important; /* Equal flex distribution */
}
.item-title-link {
  color: blue;
}
.item-title-link:hover {
  color: rgb(30, 30, 29);
}

/* ---------------------------------- */
/* Grid.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 columns for website */
  grid-template-rows: 1fr; /* 1 row for website */
  gap: 10px;
}

.grid-cell {
  /* border: 1px solid #ddd; */
  padding: 10px;
  text-align: center;
}
.item-UI {
  list-style: none;
}
.item-icon {
  font-size: 40px;
}
.item-title {
  font-size: 30px;
}
.item-paragraph {
  font-size: 20px;
  color: rgb(119, 116, 116);
}

/* --------------------------------------- */
/* Contact Us */

.flex-container-ContactUs {
  display: flex;
  margin: 20px 0 20px 0;
  padding: 50px 0 50px 0;
  background: white;
  justify-content: space-between; /* Items will be evenly distributed in the container */
}

.flex-item-ContactUs-1 {
  /* border: 1px solid #ddd; */
  padding: 10px;
  font-size: 25px;
  text-align: center;
  flex: 2; /* Equal flex distribution */
}
.flex-item-ContactUs-2 {
  /* border: 1px solid #ddd; */
  padding: 10px;
  margin-top: 100px;
  font-size: 20px;
  font-weight: bold;
  /* justify-content: end; */
  flex: 1; /* Equal flex distribution */
}

.flex-item-ContactUs-list {
  padding: 5px;
  font-size: 25px;
}
.flex-item-email {
  font-size: 20px;
  color: "blue";
}

/* -----------------------------------------------------------------------
    -----------------------------------------------------------------------
    -----------------------------------------------------------------------
    ----------------------------------------------------------------------- */
/* ipad pro */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
}
/* Surface Pro 7 */
@media screen and (min-width: 912px) and (max-width: 1368px) {
}
/* ipad Air */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }

  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}

/* surface dou */
@media screen and (min-width: 540px) and (max-width: 720px) {
}
/* IPone 14 Pro */
@media screen and (min-width: 430px) and (max-width: 932px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/* Pixel 7  */
@media screen and (min-width: 412px) and (max-width: 915px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/* Galaxy S20  */
@media screen and (min-width: 412px) and (max-width: 915px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/* IPone XR */
@media screen and (min-width: 414px) and (max-width: 896px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/* IPone 12 */
@media screen and (min-width: 390px) and (max-width: 844px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/* IPone SE */
@media screen and (min-width: 375px) and (max-width: 667px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/* samsung Galaxy S8+  */
@media screen and (min-width: 370px) and (max-width: 740px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }
  xt_p .Logo-form {
    width: 300px;
    height: 35px;
  }
  .Email0 {
    font-size: 3px;
  }
  .Eamil_form {
    font-size: 16px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 20px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}
/*  Galaxy Fold   */
@media screen and (min-width: 280px) and (max-width: 307px) {
  .flex-container-ContactUs {
    flex-direction: column;
  }

  .Logo-form {
    width: 300px;
    height: 35px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }

  .flex-item-ContactUs-2 {
    padding: 10px;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .Text-Home-2 {
    padding-top: 30px;
    direction: rtl;
    /* padding: 50px; */
  }
  .Text_p {
    font-size: 15px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .flex-item-1 {
    /* border: 1px solid #ddd; */
    padding: 10px;
    font-size: 20px;
    text-align: center;
    flex: 2; /* Equal flex distribution */
  }
}

/* ----------------------------------- */
@media screen and (min-width: 270px) and (max-width: 369px) {
  .Eamil_form {
    font-size: 13px;
  }
  .Logo-form {
    width: 190px;
    height: 20px;
  }
  .flex-item-ContactUs-list {
    padding: 5px;
    font-size: 15px;
  }
  .Text_p {
    font-size: 15px;
    font-weight: bold;
    padding-top: 12px;
    line-height: 1.5em;
    text-align: justify;
  }
  .Text-Top {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 1 column for mobile */
    grid-template-rows: 1fr; /* 1 row for mobile */
  }
  .flex-container {
    flex-direction: column;
  }
  .flex-container-ContactUs {
    flex-direction: column;
  }
}
/* todo */

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: yellow;
  color: black;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.scroll-to-top-button:hover {
  background-color: orange;
  color: white;
}
@media screen and (min-width: 280px) and (max-width: 769px) {
  .Title {
    margin-top: 70px;
    font-size: 23px;
  }
}
