.leaflet-container {
  height: 600px;
  width: 100%;
}
.cluster-icon {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: antiquewhite;
  transform: translate(-25%, -25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 2rem;
}
