.img-header {
  width: 100%;
  height: 100%;
}
/* ---------------------------------------
---------------------------------------
---------------------------------------
--------------------------------------- */
/* ipad pro */
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* Surface Pro 7 */
@media screen and (min-width: 912px) and (max-width: 1368px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* ipad Air */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* surface dou */
@media screen and (min-width: 540px) and (max-width: 720px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* IPone 14 Pro */
@media screen and (min-width: 430px) and (max-width: 932px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* Pixel 7  */
@media screen and (min-width: 412px) and (max-width: 915px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* Galaxy S20  */
@media screen and (min-width: 412px) and (max-width: 915px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* IPone XR */
@media screen and (min-width: 414px) and (max-width: 896px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* IPone 12 */
@media screen and (min-width: 390px) and (max-width: 844px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* IPone SE */
@media screen and (min-width: 375px) and (max-width: 667px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/* samsung Galaxy S8+  */
@media screen and (min-width: 360px) and (max-width: 740px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/*  Galaxy Fold  */
@media screen and (min-width: 280px) and (max-width: 653px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}
/*  Galaxy S20   */
@media screen and (min-width: 280px) and (max-width: 653px) {
  .img-header {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 280px) and (max-width: 769px) {
  .item-title {
    font-size: 23px;
  }
}
@media screen and (min-width: 280px) and (max-width: 769px) {
  .img-header {
    margin-top: 50px;
    width: 100%;
    height: 100%;
  }
}
